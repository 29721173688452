import Rails from "@rails/ujs"

document.addEventListener("DOMContentLoaded", () => {
  const submitStatusSelectors = document.querySelectorAll(
    ".care-submit-status-selector"
  )

  submitStatusSelectors.forEach((selector) => {
    selector.addEventListener("change", (e) => {
      const url = e.target.parentNode.parentNode
        .querySelector(".edit_care")
        .getAttribute("action")
      const attributeName = e.target.getAttribute("name")
      const attributeValue = e.target.value
      Rails.ajax({
        url,
        type: "PUT",
        data: `${attributeName}=${attributeValue}`,
        success(data) {
          if (data.status === "success") {
            const successDom = document.createElement("div")
            successDom.classList.add("text-xs", "text-green-500", "pt-2")
            successDom.innerText = "更新しました"

            e.target.parentNode.appendChild(successDom)
          } else if (data.status === "error") {
            alert(data.message)
          }
        },
      })
    })
  })
})
