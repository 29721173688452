import Rails from "@rails/ujs"

document.addEventListener("DOMContentLoaded", () => {
  const submitStatusSelectors = document.querySelectorAll(
    ".creative-submit-status-selector"
  )

  submitStatusSelectors.forEach((selector) => {
    selector.addEventListener("change", (e) => {
      const url = e.target.parentNode.parentNode
        .querySelector(".edit_creative")
        .getAttribute("action")
      const attributeName = e.target.getAttribute("name")
      const attributeValue = e.target.value
      Rails.ajax({
        url,
        type: "PUT",
        data: `${attributeName}=${attributeValue}`,
        success(data) {
          if (data.status === "success") {
            const successDom = document.createElement("div")
            successDom.classList.add("text-xs", "text-green-500", "pt-2")
            successDom.innerText = "更新しました"

            e.target.parentNode.appendChild(successDom)
          } else if (data.status === "error") {
            alert(data.message)
          }
        },
      })
    })
  })

  const uploadCreatives = document.querySelector(".upload-creatives")
  if (uploadCreatives) {
    uploadCreatives.addEventListener("click", (e) => {
      e.preventDefault()
      e.target.parentNode.querySelector(".upload-creatives-file").click()
    })

    const uploadCreativesFile = document.querySelector(
      ".upload-creatives-file"
    )
    uploadCreativesFile.addEventListener("change", (e) => {
      e.target.parentNode.submit()
    })
  }

  const uploadCares = document.querySelector(".upload-cares")
  if (uploadCares) {
    uploadCares.addEventListener("click", (e) => {
      e.preventDefault()
      e.target.parentNode.querySelector(".upload-cares-file").click()
    })

    const uploadCaresFile = document.querySelector(".upload-cares-file")
    uploadCaresFile.addEventListener("change", (e) => {
      e.target.parentNode.submit()
    })
  }
})
