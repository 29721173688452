import dayjs from "dayjs"

document.addEventListener("DOMContentLoaded", () => {
  const durationSelector = document.querySelector(".duration-selector")

  if (durationSelector) {
    const initializeDurationSelector = function () {
      const startDate = document.getElementById("start_date_from").value
      const endDate = document.getElementById("start_date_to").value
      const now = dayjs()
      if (
        startDate === now.startOf("month").format("YYYY-MM-DD")
        && endDate === now.endOf("month").format("YYYY-MM-DD")
      ) {
        durationSelector.value = "当月"
      } else if (
        startDate === now.subtract(1, "year").format("YYYY-MM-DD")
        && endDate === now.format("YYYY-MM-DD")
      ) {
        durationSelector.value = "一年"
      } else if (
        startDate === now.subtract(1, "month").format("YYYY-MM-DD")
        && endDate === now.format("YYYY-MM-DD")
      ) {
        durationSelector.value = "一ヶ月"
      } else if (
        startDate === now.subtract(1, "week").format("YYYY-MM-DD")
        && endDate === now.format("YYYY-MM-DD")
      ) {
        durationSelector.value = "一週間"
      } else if (
        startDate === now.format("YYYY-MM-DD")
        && endDate === now.format("YYYY-MM-DD")
      ) {
        durationSelector.value = "一日"
      } else {
        durationSelector.value = ""
      }
    }
    initializeDurationSelector()

    durationSelector.addEventListener("change", (e) => {
      const now = dayjs()

      switch (e.target.value) {
        case "当月":
          document.getElementById("start_date_from").value = now
            .startOf("month")
            .format("YYYY-MM-DD")
          document.getElementById("start_date_to").value = now
            .endOf("month")
            .format("YYYY-MM-DD")
          break
        case "一年":
          document.getElementById("start_date_from").value = now
            .subtract(1, "year")
            .format("YYYY-MM-DD")
          document.getElementById("start_date_to").value = now.format("YYYY-MM-DD")
          break
        case "一ヶ月":
          document.getElementById("start_date_from").value = now
            .subtract(1, "month")
            .format("YYYY-MM-DD")
          document.getElementById("start_date_to").value = now.format("YYYY-MM-DD")
          break
        case "一週間":
          document.getElementById("start_date_from").value = now
            .subtract(1, "week")
            .format("YYYY-MM-DD")
          document.getElementById("start_date_to").value = now.format("YYYY-MM-DD")
          break
        case "一日":
          document.getElementById("start_date_from").value = now.format("YYYY-MM-DD")
          document.getElementById("start_date_to").value = now.format("YYYY-MM-DD")
          break
        default:
          document.getElementById("start_date_from").value = null
          document.getElementById("start_date_to").value = null
      }
    })

    const startDateSelector = document.getElementById("start_date_from")
    startDateSelector.addEventListener("change", () => {
      durationSelector.value = ""
    })

    const endDateSelector = document.getElementById("start_date_to")
    endDateSelector.addEventListener("change", () => {
      durationSelector.value = ""
    })

    const creativesPageButton = document.querySelector(".to_selected_titles")
    creativesPageButton.addEventListener("click", () => {
      const checkboxes = document.querySelectorAll(".checkbox.title_selector")
      const selectedTitles = []
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          selectedTitles.push(checkbox.value)
        }
      })
      const form = document.createElement("form")
      form.method = "GET"
      form.action = "/creatives"
      form.style.display = "none"
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = "title_nos"
      input.value = selectedTitles.join(",")
      form.appendChild(input)
      document.body.appendChild(form)
      form.submit()
    })

    const initializeCreativesPageButtonStatus = function () {
      const checkboxes = document.querySelectorAll(".checkbox.title_selector")
      const selectedTitles = []
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          selectedTitles.push(checkbox.value)
        }
      })
      if (selectedTitles.length === 0) {
        creativesPageButton.setAttribute("disabled", "disabled")
      } else {
        creativesPageButton.removeAttribute("disabled")
      }
    }
    initializeCreativesPageButtonStatus()

    const titleSelectorCheckboxes = document.querySelectorAll(
      ".checkbox.title_selector"
    )
    titleSelectorCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        initializeCreativesPageButtonStatus()
      })
    })

    const changeCheckStatus = function (flag) {
      const checkboxes = document.querySelectorAll(".checkbox.title_selector")
      checkboxes.forEach((checkbox) => {
        checkbox.checked = flag
      })
      initializeCreativesPageButtonStatus()
    }

    const checkAllSelector = document.querySelector(".check_all")
    checkAllSelector.addEventListener("click", () => {
      changeCheckStatus(true)
    })

    const resetCheckSelector = document.querySelector(".reset_checks")
    resetCheckSelector.addEventListener("click", () => {
      changeCheckStatus(false)
    })
  }
})
