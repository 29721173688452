import Rails from "@rails/ujs"
import "./application.scss"
import "./application.tailwind.css"
// const imagePath = (name) => images(name, true)

import "./titles/index"
import "./titles/cares/index"
import "./creatives/index"

// images フォルダ配下をすべてロード
// eslint-disable-next-line no-unused-vars
const images = require.context("./images", true)

Rails.start()
